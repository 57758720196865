import { Controller } from "@hotwired/stimulus";
import { JSONEditor } from "@json-editor/json-editor";

export default class extends Controller {
  static values = {
    formName: String,
    jsonEditorSchema: Object,
    jsonData: Object,
  };

  initialize() {}

  connect() {
    const editors = [...document.getElementsByClassName("json-editor")];

    editors.forEach(this.createEditor.bind(this));
  }

  createEditor(editorEl) {
    // https://www.npmjs.com/package/@json-editor/json-editor#options
    const options = {
      schema: this.jsonEditorSchemaValue,
      theme: "tailwind",
      disable_collapse: true,
      disable_properties: true,
      prompt_before_delete: false,
      show_opt_in: true,
      form_name_root: this.formNameValue,
    };

    const editor = new JSONEditor(editorEl, options);

    editor.on("ready", () => {
      if (Object.keys(this.jsonDataValue).length !== 0) {
        editor.setValue(this.jsonDataValue);
      }
    });
  }
}
