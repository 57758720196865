import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "termsCheckbox", "paymentButton" ]

  connect() {
    this.termsCheckboxTarget.checked = false;
  }

  handleTermsCheckboxChange() {
    this.paymentButtonTarget.disabled = !this.termsCheckboxTarget.checked;

    if (this.termsCheckboxTarget.checked) {
      this.paymentButtonTarget.classList.remove("disabled");
    } else {
      this.paymentButtonTarget.classList.add("disabled");
    }
  }
}
