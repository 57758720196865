import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "previewEmpty",
    "previewReady",
    "indicatorDefault",
    "indicatorLoading",
    "progressBar",
    "progressPercent",
    "imageThumbnail",
    "uploader"
  ]

  static values = {
    imageAttached: Boolean
  }

  connect() {
    if (this.imageAttachedValue) {
      this.previewEmptyTarget.classList.replace("flex", "hidden")
      this.previewReadyTarget.classList.replace("hidden", "flex")
    }
  }

  handleFileSelect() {
    const file = this.uploaderTarget?.files[0]
    if (file) {
      const controller = this
      const reader = new FileReader()

      reader.onloadstart = function() {
        controller.previewEmptyTarget.classList.replace("hidden", "flex")
        controller.previewReadyTarget.classList.replace("flex", "hidden")
        controller.indicatorDefaultTarget.classList.replace("flex", "hidden")
        controller.indicatorLoadingTarget.classList.replace("hidden", "flex")
        controller.progressBarTarget.style.width = "0%"
        controller.progressPercentTarget.innerText = "0%"
        controller.uploaderTarget.disabled = true
      }

      reader.onprogress = function(event) {
        if (event.lengthComputable) {
          const percent = Math.round((event.loaded / event.total) * 100)
          controller.progressBarTarget.style.width = `${percent}%`
          controller.progressPercentTarget.innerText = `${percent}%`
        } else {
          controller.progressBarTarget.style.width = "0%"
          controller.progressPercentTarget.innerText = "-"
        }
      }

      reader.onload = function(event) {
        try {
          controller.imageThumbnailTarget.src = event.target.result
        } catch (error) {
          this.abort()
        }

        // Delay 0.3s and then show the preview
        setTimeout(() => {
          controller.previewEmptyTarget.classList.replace("flex", "hidden")
          controller.indicatorLoadingTarget.classList.replace("flex", "hidden")
          controller.previewReadyTarget.classList.replace("hidden", "flex")
          controller.progressBarTarget.style.width = "100%"
          controller.progressPercentTarget.innerText = "100%"
          controller.uploaderTarget.disabled = false
        }, 300)
      }

      reader.readAsDataURL(file)
    }
  }
}
