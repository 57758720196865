import { Controller } from "@hotwired/stimulus"
import { Dismiss } from "flowbite"

const INTERVAL = 15000

export default class extends Controller {
  static targets = ["toast"]

  dismiss
  timeout

  connect() {
    // https://flowbite.com/docs/components/alerts/#options
    const options = {
      transition: 'transition-opacity',
      timing: 'ease-out',
    }

    this.dismiss = new Dismiss(this.toastTarget, null, options)

    this.timeout = setInterval(async () => {
      await this.dismiss.hide()
    }, INTERVAL)
  }

  disconnect() {
    clearInterval(this.timeout)
    this.dismiss.destroy()
  }
}
