import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    target: String
  }

  run() {
    const target = document.querySelector(this.targetValue)

    if (target) {
      target.scrollIntoView({ behavior: "smooth" })
    } else {
      console.error(`Target ${this.targetValue} not found`)
    }
  }
}
