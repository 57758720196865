import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "counter", "minutes", "seconds"]
  static values = {
    orderId: String,
    orderExpiresAt: String,
    orderExpiredI18n: String
  }

  end = new Date()

  connect() {
    if (
      this.orderExpiresAtValue &&
      this.orderExpiredI18nValue
    ) {
      this.end = new Date(this.orderExpiresAtValue)
      this.startRefreshing()
    }
  }

  disconnect() {
    clearInterval(this.refreshTimer)
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.count()
    }, 1_000)

  }

  stopRefreshing() {
    clearInterval(this.refreshTimer)
  }

  async count() {
    const total = this.end - new Date()
    const seconds = Math.floor((total / 1_000) % 60)
    const minutes = Math.floor((total / 1_000 / 60) % 60)

    this.secondsTarget.innerHTML = String(seconds).padStart(2, '0')
    this.minutesTarget.innerHTML = String(minutes).padStart(2, '0')

    if (total < 0) {
      this.stopRefreshing()
      this.spinnerTarget.classList.replace("animate-spin-slow", "rotate-0")
      this.counterTarget.classList.replace("text-2xl", "text-lg")
      this.counterTarget.innerHTML = this.orderExpiredI18nValue

      setTimeout(() => {
        window.location.reload()
      }, 1_000)
    }
  }
}
