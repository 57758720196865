import { createIcons, icons } from "lucide"
// import { createIcons, Menu, ArrowRight, Globe } from 'lucide'

document.addEventListener("turbo:load", () => {
  createIcons({ icons })

  // createIcons({
  //   icons: {
  //     Menu,
  //     ArrowRight,
  //     Globe
  //   }
  // })
})
